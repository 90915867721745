import React from "react"
import styled from 'styled-components';

const Pad20 = styled.div`
    padding: 20px;
`;

const Pad3060 = styled.div`
    padding: 30px 60px;
    @media only screen and (max-width: 512px) {
        padding: 0px 15px 30px 15px;
    } 
`;

const QuoteStyle = styled.div`
    border: 3px solid #f1f1f1;
    border-radius: 20px;
    border-top-style: none;
`;

function Quote(){
    return(
        <div className="container-fluid">
            <Pad3060>
                <QuoteStyle>
                    <Pad20 className="row">
                        <div className="col-lg-12 text-center">
                        <p>"Anfang 2019 habe ich meine Leidenschaft f&uuml;r 
                        die Frontend Entwicklung entdeckt und eine entsprechende Ausbildung erfolgreich abgeschlossen.
                        Neben aktuellen Entwickler-Skills - die ich stetig erweitere - bringe ich langjährige Erfahrungen als
                        IT-Projektmanager mit."
                        </p>
                        <p className="blockquote-footer">Dirk Kr&uuml;ger<br /><i>Frontend Entwickler</i></p>
                        </div>
                    </Pad20>
                </QuoteStyle>
            </Pad3060>
        </div>
    )
}

export default Quote
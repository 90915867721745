import React from "react"

function Datenschutz (){
        return (
            <div className="impressum text-center fadeinquick" style={{zIndex: 2}}>
                <div className="impressum-content">
                    <h1>Datenschutz</h1>
                    <br />

                    <code style={{color: "#0F2B8E"}}><p><b>Grundlegendes</b></p>
                    <p>Diese Datenschutzerkl&auml;rung soll die Nutzer dieser Website &uuml;ber die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber - Dirk Kr&uuml;ger, Niedenau 44, 60325 Frankfurt a. M. - informieren.</p>
                    <p>Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die st&auml;ndige Weiterentwicklung dieser Webseite &Auml;nderungen an dieser Datenschutzerkl&auml;rung vorgenommen werden k&ouml;nnen, empfehlen wir Ihnen sich die Datenschutzerkl&auml;rung in regelm&auml;&szlig;igen Abst&auml;nden wieder durchzulesen.</p>						
                    <p>Definitionen der verwendeten Begriffe (z.B. &ldquo;personenbezogene Daten&rdquo; oder &ldquo;Verarbeitung&rdquo;) finden Sie in Art. 4 DSGVO.</p>

                    <p><b>Zugriffsdaten</b></p>
                    <p>Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten &uuml;ber Zugriffe auf die Website und speichern diese als &ldquo;Server-Logfiles&rdquo; auf dem Server der Website ab. Folgende Daten werden so protokolliert:</p>
                    <p>- Besuchte Website<br />- Uhrzeit zum Zeitpunkt des Zugriffes<br />- Menge der gesendeten Daten in Byte<br />- Quelle/Verweis, von welchem Sie auf die Seite gelangten<br />- Verwendete Browser<br />- Verwendetes Betriebssystem<br />- Verwendete IP-Adresse</p>							
                    <p>Die Server-Logfiles werden f&uuml;r maximal 7 Tage gespeichert und anschlie&szlig;end gel&ouml;scht. Die Speicherung der Daten erfolgt aus Sicherheitsgr&uuml;nden, um z. B. Missbrauchsf&auml;lle aufkl&auml;ren zu k&ouml;nnen. M&uuml;ssen Daten aus Beweisgr&uuml;nden aufgehoben werden, sind sie solange von der L&ouml;schung ausgenommen bis der Vorfall endg&uuml;ltig gekl&auml;rt ist.</p>

                    <p><b>Reichweitenmessung &amp; Cookies</b></p>
                    <p>Diese Website verwendet <u>keine</u> Cookies. Bei Cookies handelt es sich um kleine Dateien, welche auf Ihrem Endger&auml;t gespeichert werden. Ihr Browser greift auf diese Dateien zu. G&auml;ngige Browser bieten die Einstellungsoption, Cookies nicht zuzulassen.</p> 

                    <p><b>Erfassung und Verarbeitung personenbezogener Daten</b></p>
                    <p>Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung einwilligen. Als personenbezogene Daten gelten s&auml;mtliche Informationen, welche dazu dienen, Ihre Person zu bestimmen und welche zu Ihnen zur&uuml;ckverfolgt werden k&ouml;nnen - also beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.</p>
                    <p>Diese Website k&ouml;nnen Sie auch besuchen, ohne Angaben zu Ihrer Person zu machen.</p> 

                    <p><b>Umgang mit Kontaktdaten</b></p>
                    <p>Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktm&ouml;glichkeiten Verbindung auf, werden Ihre Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage zur&uuml;ckgegriffen werden kann. Ohne Ihre Einwilligung werden diese Daten nicht an Dritte weitergegeben.</p>
                    
                    <p><b>Rechte des Nutzers</b></p>
                    <p>Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft dar&uuml;ber zu erhalten, welche personenbezogenen Daten &uuml;ber Sie gespeichert wurden. Sie haben au&szlig;erdem das Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschr&auml;nkung oder L&ouml;schung Ihrer personenbezogenen Daten. Falls zutreffend, k&ouml;nnen Sie auch Ihr Recht auf Datenportabilit&auml;t geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtm&auml;&szlig;ig verarbeitet wurden, k&ouml;nnen Sie eine Beschwerde bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde einreichen.</p>
                    
                    <p><b>L&ouml;schung von Daten</b></p>
                    <p>Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf L&ouml;schung Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie f&uuml;r ihre Zweckbestimmung nicht mehr vonn&ouml;ten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gel&ouml;scht. Falls eine L&ouml;schung nicht durchgef&uuml;hrt werden kann, da die Daten f&uuml;r zul&auml;ssige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschr&auml;nkung der Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht f&uuml;r andere Zwecke verarbeitet.</p>

                    <p><b>Widerspruchsrecht</b></p>
                    <p>Nutzer dieser Webseite k&ouml;nnen von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen. Wenn Sie eine Berichtigung, Sperrung, L&ouml;schung oder Auskunft &uuml;ber die zu Ihrer Person gespeicherten personenbezogenen Daten w&uuml;nschen oder Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen m&ouml;chten, wenden Sie sich bitte an folgende E-Mail-Adresse: dirk@dirkdev.io</p>
                    </code>
                </div>
            </div>
        )
    }

export default Datenschutz
import React from "react"

function Impressum (){
        return (
            <div className="impressum text-center fadeinquick" style={{zIndex: 2}}>
                <div className="impressum-content" style={{overflow: "auto"}}>
                    <h1>Impressum</h1>
                    <br />

                    <code style={{color: "#0F2B8E"}}>
                    <p>
                    <b>Dirk Kr&uuml;ger</b>
                    <br />Niedenau 44
                    <br />60325 Frankfurt a. M.
                    <br />E-Mail: dirk@dirkdev.io
                    <br />
                    Telefon: +49 160 987 886 44</p>
                    <br />

                    <p><b>V.i.S.d. &sect; 55 Abs. 2 RStV</b>
                    <br />
                    Dirk Kr&uuml;ger
                    <br />
                    Niedenau 44
                    <br />
                    60325 Frankfurt a. M.
                    </p>
                    </code>

                </div>
            </div>
        )
    }

export default Impressum
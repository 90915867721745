import React from "react";
import {Link} from 'react-scroll';

function Navbar() {
    return(
        <div>
            <div className="navbar" style={{zIndex: 1}}>
                <ul className="test">
                    <Link activeClass="active" to="personal" spy={true} smooth={true} offset={0} duration={500}>
                        <div className="navtip">
                            <span className="navtiptext fadeout">Persönliches</span>
                            <li></li>    
                        </div>
                    </Link>
                    <Link activeClass="active" to="skillset1" spy={true} smooth={true} offset={-30} duration={500}>
                        <div className="navtip">
                            <span className="navtiptext fadeout">Ausbildung</span>
                            <li></li>
                        </div>
                    </Link>
                    <Link activeClass="active" to="skillset2" spy={true} smooth={true} offset={-30} duration={500}>
                        <div className="navtip">
                            <span className="navtiptext fadeout">Frameworks</span>
                            <li></li>
                        </div>
                    </Link>
                    <Link activeClass="active" to="portfolio" spy={true} smooth={true} offset={-30} duration={500}>
                        <div className="navtip">
                            <span className="navtiptext fadeout">Portfolio</span>
                            <li></li>
                        </div>
                    </Link>
                    <Link activeClass="active" to="reviews" spy={true} smooth={true} offset={-30} duration={500}>
                        <div className="navtip">
                            <span className="navtiptext fadeout">Reviews</span>
                            <li></li>
                        </div>
                    </Link>
                </ul>
            </div>
            
        </div>
    )
}

export default Navbar
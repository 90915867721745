import React from "react"
import Progress from "./Progress"
import {Element} from 'react-scroll';

function Skillset1() {
    return(
        <div className="container-fluid">
            <Element name="skillset1" className="element">
            <div className="pad80SmallTop">
                <div className="shadowBox">  
                    <div className="row" style={{padding: 30}}>   
                        <div className="col-lg-4">
                            <div>
                                <h4 className="text-uppercase padBottom20 grey">Ausbildung</h4>
                            </div>
                            <div className="leftBorderBox padLeft20 lineHeight">
                                <p>Traversy Media | codewithmosh.com<br /><span className="font-weight-bold">- Angular -</span><br />01/2020 - heute</p>
                                <p>w3schools | vschool.io | freeCodeCamp<br /><span className="font-weight-bold">JavaScript | CSS | HTML</span><br /><span className="font-weight-bold">- Vue.js | React | Bootstrap -</span><br />05/2019 - 12/2019</p>
                                <p>edx | Harvard University<br /><span className="font-weight-bold">C-Programmierung</span><br />03/2019 - 04/2019</p>
                                <p>Universität Hamburg<br /><span className="font-weight-bold">M.Sc. | B.Sc. Betriebswirtschaftslehre</span><br />2006 - 2012</p>
                            </div>
                        </div>    
                        
                        <div className="col-lg-4">
                            <div>
                                <h4 className="text-uppercase padBottom20 grey">Berufserfahrung</h4>
                            </div>
                            <div className="leftBorderBox padLeft20">
                                <p>Stiftung Hamburger Zukunftsstifter<br /><span className="font-weight-bold">Stiftungsvorstand</span><br />06/2018 - heute</p>
                                <br /><p>bevestor | DekaBank (FinTech | InnoLab)<br /><span className="font-weight-bold">Senior Projektmanager für Digitalisierung</span><br />10/2016 - 05/2018</p>
                                <br /><p>comdirect bank AG<br /><span className="font-weight-bold">Projektmanager Inhouse Consulting</span><br />10/2013 - 09/2015</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <h4 className="text-uppercase padBottom20 grey">Entwickler Skills</h4>
                            </div>
                            <div className="leftBorderBox padLeft20">
        
                                <Progress
                                dataProgress={{
                                    name: "JavaScript",
                                    start: 0,
                                    end: 80,
                                    duration: 5000
                                }}
                                />
                                    
                                <br />
                                <Progress
                                dataProgress={{
                                    name: "CSS",
                                    start: 0,
                                    end: 95,
                                    duration: 5000
                                }}
                                />

                                <br />
                                <Progress
                                dataProgress={{
                                    name: "HTML",
                                    start: 0,
                                    end: 99,
                                    duration: 5000
                                }}
                                />
                                <br />
                                <br />
                                
                                <h4>Tools | Technologies</h4>
                                <div className="d-flex flex-wrap">
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>Visual Studio Code</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>Git</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>GitHub</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>npm</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>webpack</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>BEM</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>ESLint</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>JSON</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>jQuery</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>styled components</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>Jira</kbd></div>
                                    <div><kbd style={{backgroundColor: "#0F2B8E", marginRight: 3}}>Confluence</kbd></div>                      
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Element>
        </div>
    )
}

export default Skillset1
import React from "react"
import VisibilitySensor from 'react-visibility-sensor';

class CanvasArcContainer extends React.Component {
    constructor() {
        super();
        this.state={
            count: 0,
            hasTriggered: false
        }
        this.counter = this.counter.bind(this);
    }

    counter(isVisible, props) {
        if(isVisible === true) {
            this.setState({hasTriggered: true});
            
            this.setState({count: this.props.data.start});
            const range = this.props.data.end - this.props.data.start;
            const stepTime = Math.abs(Math.floor(this.props.data.duration / range));
        
            this.interval = setInterval(() => {
                this.setState({count: this.state.count + 1}); 
                
                const canvas = this.refs.canvas;
                const ctx = canvas.getContext("2d");
                ctx.canvas.width = 200;
                ctx.canvas.height = 150;
                ctx.beginPath();
                ctx.arc(100,75,70,1.5*Math.PI,(1.5+(0.02*this.state.count))*Math.PI);
                ctx.lineWidth = 10;
                ctx.strokeStyle = this.props.data.color;
                ctx.stroke();

                if(this.state.count === this.props.data.end ) {
                    clearInterval(this.interval);
                }
            }, stepTime);
        }
    }

    render() {
        return(
            <div className="col-lg-3 text-center">
                <VisibilitySensor
                    onChange={(isVisible) => this.counter(isVisible)}
                    partialVisibility={true}
                    intervalDelay={2000}
                    active={!this.state.hasTriggered}
                >
                <div>
                    <h4 className="text-uppercase grey"><kbd>{this.props.data.name}</kbd></h4>
                </div>
                </VisibilitySensor>
                <div className="canvas">
                    <canvas ref="canvas" />
                    <div>
                        <p><code style={{color: "black", fontSize: "1.2rem"}}>{this.state.count}%</code></p>
                    </div>
                </div>
            </div>
        )
    }
}

export default CanvasArcContainer
import React from "react"

function Portfolio(props) {
    return(
        <div className="col-lg-4 pad20">
            <div className="portfolio pad10 shadowBox">
                <a target="_blank" rel="noopener noreferrer" href={"https://www."+props.portData.url} className="text-center portfolioLink"><h5><code style={{color: "black"}}>{props.portData.url}</code></h5></a>
                <img className="img-fluid" src={props.portData.jpg} alt={props.portData.url} />
                <br /><br />
                <table className="table-hover" style={{width: "100%", margin: "auto"}}>
                    <tbody>
                        <tr>
                            <td className="text-right"><code style={{color: "black"}}>Tools:</code></td>
                            <td><code style={{color: "black", letterSpacing: "-1px"}}>{props.portData.tools}</code></td>                    
                        </tr>
                        <tr style={{background: "white"}}>
                            <td className="text-right"><code style={{color: "black"}}>Frameworks:</code></td>
                            <td><code style={{color: "black", letterSpacing: "-1px"}}>{props.portData.frameworks}</code></td>                    
                        </tr>
                        <tr>
                            <td className="text-right"><code style={{color: "black"}}>App:</code></td>
                            <td><code style={{color: "black", letterSpacing: "-1px"}}>{props.portData.app}</code></td>                    
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Portfolio
import React from "react";
import {Element} from 'react-scroll';

function Review() {
    return(
        <div className="container-fluid">
            <Element name="reviews" className="element">
            <div className="pad80SmallTop row">
                <div className="col-md-12" style={{position: "relative"}}>
                    <hr />
                    <div className="centerHeader">
                        <h4 className="text-uppercase grey text-center">reviews</h4>
                    </div>
                </div>
                <div className="col-lg-4 review text-center pad20">
                    <img src="https://www.dirkdev.de/images/pt.jpg" alt="Philmon" />
                    <p>"Sie können sich zu 100% auf ihn verlassen. Wenn er sagt, er liefert, liefert er."</p>
                    <p className="blockquote-footer">Philmon Tesfay<br /><i>Senior Frontend Entwickler</i></p>
                </div>
            
                <div className="col-lg-4 review text-center pad20">
                    <img src="https://www.dirkdev.de/images/kt.jpg" alt="Kerstin" />
                    <p>"Dirk versteht schnell das Big Picture und priorisiert seine Tasks danach. Aus PO-Sicht super-hilfreich im agilen Team."</p>
                    <p className="blockquote-footer">Kerstin Tiemann<br /><i>Product Owner</i></p>
                </div>
            
                <div className="col-lg-4 review text-center pad20">
                    <img src="https://www.dirkdev.de/images/nb.jpg" alt="Nico" />
                    <p>"Ich habe großen Respekt vor der Zielstrebigkeit, dem Willen und der Freude, mit der Dirk seinen beruflichen Neustart als Frontend Entwickler verfolgt."</p>
                    <p className="blockquote-footer">Dr. Nico Bourbonus<br /><i>Produktmanager eBusiness | Data Analyst</i></p>
                </div>
                
                <div style={{height: 100, width: 100}}>
                </div>

            </div>
            </Element>
        </div>
    )
}

export default Review
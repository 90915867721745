import React from "react"
import Impressum from "./Impressum"
import Datenschutz from "./Datenschutz"

class Footer extends React.Component {
    constructor(){
        super()
        this.state = {
            displayImp: false,
            displayDataProt: false
        }
        this.ImpressumClick = this.ImpressumClick.bind(this)
        this.DatenschutzClick = this.DatenschutzClick.bind(this)
    }
    
    ImpressumClick(){
        this.setState(prevState => {
            return {
                displayImp: !prevState.displayImp
            }})    
    }

    DatenschutzClick(){
        this.setState(prevState => {
            return {
                displayDataProt: !prevState.displayDataProt
            }})    
    }

    render(){
        const stylesImp = this.state.displayImp ? {display: "inline"} : {display: "none"}
        const stylesDataProt = this.state.displayDataProt ? {display: "inline"} : {display: "none"}
        return(
            <div>
                <div>
                    <div className="BackButton" style={stylesImp}>
                        <div className="button" onClick={ () => this.setState({displayImp: false})}>Zur&uuml;ck</div>
                    </div>    
                </div>

                <div>
                    <div className="BackButton" style={stylesDataProt}>
                        <div className="button" onClick={ () => this.setState({displayDataProt: false})}>Zur&uuml;ck</div>
                    </div>    
                </div>
                
                <div>
                    <div style={stylesImp}>
                        <Impressum />
                    </div>    
                </div>

                <div>
                    <div style={stylesDataProt}>
                        <Datenschutz />
                    </div>    
                </div>

                <div className="footer text-center">
                    <p><span onClick={this.ImpressumClick}>Impressum</span> | <span onClick={this.DatenschutzClick}>Datenschutz</span></p>
                    <p>&copy; 2020 Dirk Kr&uuml;ger</p>               
                </div>
            </div>
        )
    }
}

export default Footer
import React from 'react';
import styled from 'styled-components';
import photo from './images/photo.jpg';
import {Element} from 'react-scroll';
import Quote from "./Quote";

class Personal extends React.Component {
  constructor() {
    super();
    this.state = {
      personal: {
        dob: "",
        nationality: "",
        languages: [],
        email: "",
        phone: "",
        location: ""
      }
    }
  }

  componentDidMount() {
    fetch("./dk.json")
      .then(response => response.json())
      .then(data => {
        this.setState( {personal: data} )
      })
  }
  
  render(){
    const Pad20 = styled.div`
      padding: 20px;
    `;

    const Pad60 = styled.div`
      padding: 60px 60px 0px 60px;
      @media only screen and (max-width: 512px) {
        padding: 10px;
      }
    `; 

    const Slider = styled.div`
      overflow: hidden;
      height: 65px;
    `; 

    return (
      <main>
        <Element name="personal" className="element">
        <div className="container-fluid">
          <Pad60 className="row">
              <Pad20 className="col-lg-4">
                <img className="img-fluid" src={photo} alt="Dirk Krüger" />
              </Pad20>
              <Pad20 className="col-lg-8">  
                <Slider>
                  <div className="slidetop"><h6 style={{letterSpacing: -3}} className="display-4 text-uppercase">Dirk Krüger</h6>
                  </div>
                </Slider>
                <Slider>
                  <div className="fadein"><h6 style={{color: "#999999", letterSpacing: 6}} className="display-6 text-uppercase">Frontend Entwickler</h6>
                  </div>
                </Slider>
                <table className="table table-hover fadein-delay" style={{width: "100%"}}>
                  <tbody>
                    <tr>
                      <td>Sprachen:</td>
                      <td>
                        {this.state.personal.languages !== undefined && this.state.personal.languages.map(item => (
                          <span key={item}>{item} </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>E-Mail:</td>
                      <td><a href={"mailto:"+this.state.personal.email}>{this.state.personal.email}</a></td>
                    </tr>
                    <tr>
                      <td>Telefon:</td>
                      <td>{this.state.personal.phone}</td>
                    </tr>
                    <tr>
                      <td>Location:</td>
                      <td>{this.state.personal.location}</td>
                    </tr>
                  </tbody>
                </table>
              </Pad20>
          </Pad60>                
        </div>
        <Quote />
        </Element>
      </main>
    );
  }
}

export default Personal;

import React from "react";

import Navbar from "./components/Navbar";
import Personal from "./components/Personal";
import Skillset1 from "./components/Skillset1";
import Skillset2 from "./components/Skillset2";
import PortfolioContainer from "./components/PortfolioContainer";
import Review from "./components/Review";
import Footer from "./components/Footer";


function App() {
  return (
    <div>
      <Personal />
      <Navbar />
      <Skillset1 />
      <Skillset2 />
      <PortfolioContainer />
      <Review />
      <Footer />
    </div>
  );
}

export default App;





import React from "react";
import VisibilitySensor from 'react-visibility-sensor';

class Progress extends React.Component{
    constructor() {
        super();
        this.state={
            count: 0,
            hasTriggered: false
        }
        this.progressCounter = this.progressCounter.bind(this);
    }
    
    progressCounter(isVisible, props){
        if(isVisible === true) {
            this.setState({hasTriggered: true});
       
            this.setState({count: this.props.dataProgress.start});
            const range = this.props.dataProgress.end - this.props.dataProgress.start;
            const stepTime = Math.abs(Math.floor(this.props.dataProgress.duration / range));
            
            this.interval = setInterval(() => {
                this.setState({count: this.state.count + 1}); 
                if(this.state.count === this.props.dataProgress.end ) {
                    clearInterval(this.interval)
                }
            }, stepTime);        
        }
    }    
    
    render(){
        return(
            <div>
                <VisibilitySensor
                            onChange={(isVisible) => this.progressCounter(isVisible)}
                            partialVisibility={true}
                            intervalDelay={2000}
                            active={!this.state.hasTriggered}
                >
                    <h4><kbd>{this.props.dataProgress.name}</kbd></h4>
                </VisibilitySensor>
                <div className="progress border" style={{backgroundColor: "#787586"}}>
                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: this.state.count+'%', backgroundColor: "#0F2B8E"}}>{this.state.count}%</div>
                </div>
            </div>
        )
    }
}
export default Progress
import React from "react"
import Portfolio from "./Portfolio"
import {Element} from 'react-scroll';

function PortfolioContainer() {
    return(
        <div className="container-fluid">
            <Element name="portfolio" className="element">
            <div className="row pad80SmallTop">
                <div className="col-lg-12" style={{position: "relative"}}>
                    <hr />
                    <div className="centerHeader">
                        <h4 className="text-uppercase grey text-center">portfolio</h4>
                    </div>
                </div>
            
                <Portfolio 
                portData={{
                    url: "zukunftsstifter.de",
                    jpg: "https://www.dirkdev.de/images/zukunftsstifter.jpg",
                    tools: "Windows txt-Editor",
                    frameworks: "keine -> Handmade",
                    app: "keine"
                }}
                />

                <Portfolio 
                portData={{
                    url: "dirkdev.io",
                    jpg: "https://www.dirkdev.de/images/dirkdevio.jpg",
                    tools: "Visual Studio Code",
                    frameworks: "React | Bootstrap",
                    app: "Progressive Web App"
                }}
                />

                <Portfolio 
                portData={{
                    url: "nettoeinkommensrechner.de",
                    jpg: "https://www.dirkdev.de/images/netcalc.jpg",
                    tools: "Visual Studio Code",
                    frameworks: "Vue.js",
                    app: "Progressive Web App"
                }}
                />

            </div>
            </Element>   
        </div>
    )
}

export default PortfolioContainer
import React from "react"
import CanvasArcContainer from "./CanvasArcContainer"
import {Element} from 'react-scroll';

function Skillset2() {
    return(
        <div className="container-fluid">
            <Element name="skillset2" className="element">
            <div className="pad80SmallTop">
                <div className="shadowBox">
                    <div className="pad20 row">  
                            
                        <div className="col-lg-12 text-center jumbotron">
                            <h4 className="text-uppercase">libraries | frameworks</h4>
                            <p>Mein aktueller Wissensstand der verschiedenen Libraries | Frameworks</p>
                        </div>
                        
                        <CanvasArcContainer 
                        data={{
                            name: "Angular",
                            color: "#ACA9BB",
                            start: 0,
                            end: 30,
                            duration: 5000
                        }}
                        />

                        <CanvasArcContainer 
                        data={{
                            name: "Vue.js",
                            color: "#0F2B8E",
                            start: 0,
                            end: 80,
                            duration: 5000
                        }}
                        />

                        <CanvasArcContainer 
                        data={{
                            name: "React",
                            color: "#81000C",
                            start: 0,
                            end: 80,
                            duration: 5000
                        }}
                        /> 

                        <CanvasArcContainer 
                        data={{
                            name: "Bootstrap",
                            color: "#BE2D36",
                            start: 0,
                            end: 90,
                            duration: 5000
                        }}
                        />
                        
                    </div>
                </div>
            </div>
            </Element>
        </div>
    )
}

export default Skillset2